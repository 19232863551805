import { useEffect, useState } from 'react'

// MUIS
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconGitHub from '@mui/icons-material/GitHub'

// SERVICES
import { getGithubData } from 'services/github'

// STYLES
import useStyles from './buttonGithubUsestyles'

// UTILITIES
import { abbreviateNumber } from 'utilities/conversion'

const ButtonGithub = (props) => {
  const classes = useStyles()

  // STATES
  const [ githubData, setGithubData ] = useState({})


  useEffect(() => {
    const loadDataGithub = async () => {
      const {data} = await getGithubData()
      setGithubData(data ?? {})
    }
    
    loadDataGithub()
  }, [])

  return (
    <Stack direction='row' alignItems='flex-end' justifyContent='flex-end' className={classes.customGithubSection}>
      <Button className={classes.githubButton} href={githubData?.html_url ?? ''} startIcon={<IconGitHub/>}>
        <Stack flex='1' alignItems='center'>
          {
            githubData?.stargazers_count 
              ? abbreviateNumber(githubData?.stargazers_count ?? 0)
              : ''
          }
        </Stack>
      </Button>
    </Stack>
  )
}

export default ButtonGithub