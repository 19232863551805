// CONSTANTS
import { values } from 'constants/values'

// MUIS
import Stack from '@mui/material/Stack'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip 
    {...props} 
    classes={{ popper: className }} 
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'unset',
    padding: 0,
    margin: 0,
    fontSize: 'unset',
  },
  '@media only screen and (max-height: 820px) and (min-width: 1200px)': {
    [`& .${tooltipClasses.tooltip}`]: {
      zoom: values.zoomValue,
    },
  }
}))

const CustomTooltip = (props) => {
  return (
    <Stack className='neutralize-zoom-tooltip'>
      <StyledTooltip className='no-zoom' {...props}/>
    </Stack>
  )
}

export default CustomTooltip