
import { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

// COMPONENTS
import AppBarDesktop from './AppBarDesktop/AppBarDesktop'
import AppBarMobile from './AppBarMobile/AppBarMobile'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

const AppBar = () => {
  const pathLocation = useLocation()

  // CONTEXTS
  const { breakpointType } = useContext(AllPagesContext)

  // STATES
  const [anchorEl, setAnchorEl] = useState(null)

  const isNavigationItemActive = (inputCurrentPath, inputItemPath) => {
    if(inputCurrentPath.includes('/solution') || inputItemPath.toLowerCase().includes('solution')) {
      if(inputCurrentPath.replace('/solution-', '').replace(/-/g, ' ') === inputItemPath) return true
      else if (inputCurrentPath.includes('/solution') && inputItemPath.toLowerCase().includes('solution')) return true
      else return false
    }
  
    const currentPage = inputCurrentPath.split('/')[1]
    const path = inputItemPath.replace('/', '')
    return (currentPage === path || currentPage.includes(path)) ? true : false
  }

  const handleClick = (event, title) => {
    if (anchorEl !== event.currentTarget && title === 'Solutions') {
      setAnchorEl(event.currentTarget)
    }
  }

  // CHECK IS ON PAGE NO HAVE HERO
  const isPageNoHeroSection = () => {
    if(pathLocation.pathname.includes('/blogs/')) return true
    else return false
  }

  return (
    <>
      {(breakpointType === 'lg' || breakpointType === 'xl')
        ? (
          <AppBarDesktop
            isNavigationItemActive={isNavigationItemActive}
            handleClick={handleClick}
            isPageNoHeroSection={isPageNoHeroSection}
            anchorEl={anchorEl}
          />
        )
        : (
          <AppBarMobile
            isNavigationItemActive={isNavigationItemActive}
            isPageNoHeroSection={isPageNoHeroSection}
          />
        )}
    </>
  )
}

export default AppBar