// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: 'white',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
}))

export default useStyles