// CONSTANTS
import { values } from 'constants/values'

// MUIS
import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { alpha } from '@mui/material/styles'

const GlobalStyles = () => {
  const zoomValue = 0.85

  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        // ALL ELEMENTS
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          fontFamily: values.fontFamilyDmMono,
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
          imageRendering: 'optimizeQuality',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        },

        // COMPONENTS
        '.appbarResponsive': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
          boxShadow: 'none',
          color: theme.palette.common.white,
          fontFamily: theme.typography.fontFamily,
          marginBottom: 0,
          flexDirection: 'column',
          justifyContent: 'center',
          zIndex: theme.zIndex.appBar,
          transition: '0.4s',

          '&--bordered': {
            borderBottom: `3px solid ${theme.palette.common.black}`,
          },
          '& .appbarLogo': {
            fill: theme.palette.common.white,
            '& .appLogoRed': {
              fill: theme.palette.common.white,
            }
          },
          '& .barHamburger': {
            color: theme.palette.common.white,
          },
          '& .menuToolbar': {
            borderBottom: 'none'
          },
          '& .menuItemDesktop': {
            color: theme.palette.common.white,
            textDecoration: 'none',
            '&.active': {
              color: theme.palette.common.white,
              textDecoration: 'underline !important',
              '&:hover': {
                textDecoration: 'underline !important',
              }
            },
            '&:hover': {
              textDecoration: 'underline !important',
            }
          },

          '&--scrolled': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,

            '& .appbarLogo': {
              fill: theme.palette.common.black,
              '& .appLogoRed': {
                fill: theme.palette.primary.main,
              }
            },
            '& .barHamburger': {
              color: theme.palette.common.black,
            },
            '& .menuToolbar': {
              borderBottom: `1px solid ${theme.palette.common.black}`
            },
            '& .menuItemDesktop': {
              color: theme.palette.common.black,
              '&.active': {
                color: theme.palette.primary.main,
                '&:hover': {
                  textDecoration: 'underline !important',
                  color: theme.palette.primary.main,
                }
              },
              '&:hover': {
                textDecoration: 'underline !important',
                color: theme.palette.primary.main,
              }
            },
          }
        },
        '.buttonHero': {
          [theme.breakpoints.only('xs')]: {
            fontSize: 16,
            height: 48,
            width: 240,
          },
          [theme.breakpoints.only('sm')]: {
            fontSize: 18,
            height: 52,
            width: 280,
          },
          [theme.breakpoints.only('md')]: {
            fontSize: 20,
            height: 56,
            width: 320,
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: 24,
            height: 60,
            width: 360,
          },
        },
        '.buttonWhite': {
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${theme.palette.common.black} !important`
          },
        },
        '.containerMaxWidth1400': {
          maxWidth: '1400px !important',
        },
        '.containerMaxWidth700': {
          maxWidth: '700px !important',
        },
        
        // GENERAL
        '.flex': {
          display: 'flex'
        },
        '.alignItemsCenter': {
          alignItems: 'center'
        },
        '.alignSelfCenter': {
          alignSelf: 'center',
        },
        '.colorTextPrimary': {
          color: theme.palette.text.primary,
        },
        '.fontWeight700': {
          fontWeight: 700
        },
        '.justifyContentEnd': {
          justifyContent: 'flex-end !important'
        },
        '.textAlignCenter': {
          textAlign: 'center'
        },
        '.width100': {
          width: '100%'
        },

        // SCROLLBAR
        '&::-webkit-scrollbar': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.16),
        },
        '&::-webkit-scrollbar-thumb': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.2),
        },

        // ZOOM
        '@media only screen and (max-height: 820px) and (min-width: 1200px)': {
          'body': {
            zoom: zoomValue,
          },
          '.zoom': {
            zoom: zoomValue,
          },
          '.no-zoom': {
            zoom: 1 / zoomValue,
          },
          '.neutralize-zoom-tooltip': {
            zoom: 1 / values.zoomValue,
            '& .MuiIconButton-root .MuiSvgIcon-root': {
              zoom: values.zoomValue,
            }
          }
        },
      })}
    />
  )
}

export default GlobalStyles