// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  customGithubSection: {
    width: 132,
    marginRight: 40,
    [theme.breakpoints.down('md')]: {
      marginRight: 16,
    },
  },
  githubButton:{
    width: 90,
    height: 28,
    fontSize: 12,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: '0 8px 0 0',
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
    '& .MuiButton-startIcon': {
      backgroundColor: theme.palette.common.white,
      height: 28,
      color: theme.palette.common.black,
      padding: 3,
      border: `1.5px solid ${theme.palette.common.black}`,
    }
  },
}))

export default useStyles