import { Suspense, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

// AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

// LAYOUTS
import Landing from 'layouts/Landing/Landing'

// ROUTES
import routes from 'routes/routes'

// SERVICES
import { getRootData } from 'services/root'

const App = () => {
  const getRootAPIData = async () => {
    const { data } = await getRootData()
    document.getElementsByTagName('meta')['keywords'].content = data?.data?.attributes?.Keywords
  }

  const getRouteComponent = (inputItem) => {
    if(inputItem.routeType === 'landing'){
      return (
        <Landing>
          {inputItem.element}
        </Landing>
      )
    } else if (inputItem.routeType === 'free') return inputItem.element
  }

  useEffect(() => {
    getRootAPIData()
    AOS.init()
  }, [])

  return (
    <Suspense fallback={<div/>}>
      <Routes>
        {routes.map((item, index) => (
          <Route 
            key={index}
            path={item.path} 
            element={getRouteComponent(item)}
          />
        ))}
      </Routes>
    </Suspense>
  )
}

export default App