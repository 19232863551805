import { lazy } from 'react'

// PAGES
const Blog = lazy(() => import('pages/Blog/Blog'))
const BlogDetail = lazy(() => import('pages/BlogDetail/BlogDetail'))
const ContactUs = lazy(() => import('pages/ContactUs/ContactUs'))
const Error = lazy(() => import('pages/Error'))
const Features = lazy(() => import('pages/Features/Features'))
const Home = lazy(() => import('pages/Home/Home'))
const HowItWork = lazy(() => import('pages/HowItWork/HowItWork'))
const Rules = lazy(() => import('pages/Rules/Rules'))
const Pricing = lazy(() => import('pages/Pricing/Pricing'))
const Solutions = lazy(() => import('pages/Solutions/Solutions'))

const routes = [
  {
    path: '/',
    element: <Home />,
    routeType: 'landing',
  },
  {
    path: '/blogs',
    element: <Blog />,
    routeType: 'landing',
  },
  {
    path: '/blogs/detail',
    element: <BlogDetail />,
    routeType: 'landing',
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
    routeType: 'landing',
  },
  {
    path: '/error',
    element: <Error />,
    routeType: 'free',
  },
  {
    path: '/features',
    element: <Features />,
    routeType: 'landing',
  },
  {
    path: '/how-it-works',
    element: <HowItWork />,
    routeType: 'landing',
  },
  {
    path: '/pricing',
    element: <Pricing />,
    routeType: 'landing',
  },
  {
    path: '/solution-retail',
    element: <Solutions />,
    routeType: 'landing',
  },
  {
    path: '/solution-law-enforcement',
    element: <Solutions />,
    routeType: 'landing',
  },
  {
    path: '/solution-government',
    element: <Solutions />,
    routeType: 'landing',
  },
  {
    path: '/solution-outsourcing',
    element: <Solutions />,
    routeType: 'landing',
  },
  {
    path: '/privacy-policy',
    element: <Rules />,
    routeType: 'landing',
  },
  {
    path: '/support-regulation',
    element: <Rules />,
    routeType: 'landing',
  },
  {
    path: '/terms-of-service',
    element: <Rules />,
    routeType: 'landing',
  },
]

export default routes 