import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// ASSETS
import LogoWorxBlack from '../LogoWorxBlack'

// COMPONENTS
import ButtonGithub from 'components/ButtonGithub'
import CustomTooltipContainer from 'components/CustomTooltip'

// CONSTANTS
import { buttonList, navigationList, solutionList } from '../appBarConstants'

// GSAP
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import MuiAppBar from '@mui/material/AppBar'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'

// STYLES
import useStyles from './appBarDesktopUseStyles'

const AppBarDesktop = (props) => {
  const { isNavigationItemActive, handleClick, isPageNoHeroSection } = props
  gsap.registerPlugin(ScrollTrigger)

  const classes = useStyles()
  const location = useLocation()

  // ANIMATE SCROLL APPBAR
  const animateAppBarScroll = () => {
    ScrollTrigger.create({
      start: 'top -40',
      end: 99999,
      toggleClass: {className: 'appbarResponsive--scrolled', targets: '#menu-root'}
    })
    ScrollTrigger.create({
      start: 'top -40',
      end: 99999,
      toggleClass: {className: 'appbarResponsive--bordered', targets: '#menu-root'}
    })
  }

  useEffect(() => {
    animateAppBarScroll()
  }, [])

  return (
    <MuiAppBar id='menu-root' className={`${classes.appBarRoot} ${!isPageNoHeroSection() && 'appbarResponsive'}`} position='fixed'>
      <Toolbar className={`${classes.toolbar} containerMaxWidth1400`}>
        {/* LOGO */}
        <Box className={classes.logoWrap}>
          <Link href='/' className={classes.logolinkWrap}>
            <LogoWorxBlack width='109' height='32' className='appbarLogo'/>
          </Link>
        </Box>

        {/* LIST MENU */}
        <Stack direction='row' className={classes.listMenuWrap}>
          <List className={`${classes.listMenu} no-zoom`}>
            {navigationList && navigationList.map((item, index) => (
              item.title === 'Solutions' ?
                <CustomTooltipContainer placement='bottom-start' key={index} classes={{ popper: classes.solutionMenuItem }} title={
                  <Paper className={classes.tooltipMenu}>
                    {solutionList?.map((item, index) => (
                      <MenuItem key={index} className={`${classes.menuItem} zoom`}>
                        <Link
                          underline='none'
                          href={item.path}
                          className={
                            isNavigationItemActive(location.pathname, item.title.toLowerCase())
                              ? `${classes.listMenuLink} colorTextPrimary menuPopupItem active`
                              : `${classes.listMenuLink} colorTextPrimary`
                          }
                        >
                          {item.title}
                        </Link>
                      </MenuItem>
                    ))}
                  </Paper>
                }>
                  <Link
                    className={isNavigationItemActive(location.pathname, item.title.toLowerCase())
                      ? `${classes.listMenuLink} ${classes.menuSolutions} menuItemDesktop active`
                      : `${classes.listMenuLink} ${classes.menuSolutions} menuItemDesktop`
                    }
                    underline={isNavigationItemActive(location.pathname, item.title.toLowerCase()) ? 'always' : 'hover'}
                    onMouseOver={(e) => handleClick(e, item.title)}
                  >
                    <Typography variant='subtitle1' className='fontWeight700 zoom'>
                      {item.title}
                    </Typography>
                    <IconArrowDropDown sx={{ display: 'inline' }} />
                  </Link>
                </CustomTooltipContainer>
                :
                <ListItem key={index} className={classes.listMenuItem}>
                  <Link
                    className={
                      isNavigationItemActive(location.pathname, item.title.toLowerCase())
                        ? `${classes.listMenuLink} menuItemDesktop active`
                        : `${classes.listMenuLink} menuItemDesktop`
                    }
                    underline={isNavigationItemActive(location.pathname, item.title.toLowerCase()) ? 'always' : 'hover'}
                    href={`/${item.title.toLowerCase()}`}
                  >
                    <Typography variant='subtitle1' className='fontWeight700 zoom'>
                      {item.title}
                    </Typography>
                  </Link>
                </ListItem>
            ))}
          </List>
        </Stack>

        {/* GITHUB INFO */}
        <ButtonGithub />

        {/* BUTTON APPBAR */}
        {buttonList && (<Box className={classes.buttonWrap}>
          {/* LIST BUTTON */}
          <List className={classes.listButton}>
            {buttonList.map((item, index) => (
              <ListItem key={index} className={classes.listButtonItem}>
                <Button
                  variant={item.variant}
                  color={item.color}
                  href={item.path}
                  className={`${classes.buttonOutlinedCustom} ${item?.classes}`}
                >
                  {item.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Box>)}
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBarDesktop