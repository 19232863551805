// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  toolbarActive: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',

    '& .appbarLogo': {
      fill: `${theme.palette.common.black} !important`,
      '& .appLogoRed': {
        fill: `${theme.palette.primary.main} !important`,
      }
    },
    '& .barHamburger': {
      stroke: `${theme.palette.common.black} !important`,
    },
  },
  toolbar: {
    flex: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '16px 60px',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.common.black}`,
    transition: 'ease-in-out 1s',
    '&.active': {
      backgroundColor: theme.palette.common.white,
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 24px',
    }
  },
  menuActionWrap: {
    width: '100%',
    margin: '0 auto'
  },
  logoWrap: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
  },
  logolinkWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 72,
    cursor: 'pointer'
  },
  buttonHamburger: {
    marginRight: -8,
  },
  iconClose: {
    color: theme.palette.common.black,
    height: 20,
    width: 20
  },
  iconHamburger: {
    color: theme.palette.common.white,
    height: 20,
    width: 20
  },
  iconActionToggle: {
    fill: 'none',
    strokeLinecap: 'round'
  },
  menuContent: {
    display: 'none',
    opacity: 0,
    position: 'fixed',
    backgroundColor: '#fff',
    top: 72,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      top: 72
    }
  },
  listMenuWrap: {
    padding: '32px 60px',
    [theme.breakpoints.down('md')]: {
      padding: '32px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '32px 24px'
    }
  },
  listMenuParent: {
    width: '100%',
    padding: 0,
  },
  menuItemParent: {
    padding: 0,
    marginBottom: 24,
    minHeight: 'auto !important',
    '&:last-child': {
      marginBottom: 0,
    }
  },
  menuItemLinkParent: {
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&.active': {
      color: theme.palette.primary.main,
    }
  },
  listMenuChildren: {
    marginTop: 24,
    marginLeft: 28,
    padding: 0,
  },
  menuItemChildren: {
    padding: 0,
    marginBottom: 24,
    minHeight: 'auto !important',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuItemLinkChildren: {
    color: theme.palette.text.secondary,
    '&.active': {
      color: theme.palette.primary.main,
    }
  },
  iconMenuDropdown: {
    color: theme.palette.common.black,
    '&.active': {
      color: theme.palette.primary.main,
    }
  },
  menuContentDivider: {
    borderColor: theme.palette.common.black,
  },
  listButtonWrap: {
    padding: '32px 60px',
    [theme.breakpoints.down('md')]: {
      padding: '32px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '32px 24px'
    }
  },
  buttonMenu: {
    marginBottom: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  footerWrap: {
    textAlign: 'center',
    padding: '32px 60px',
    [theme.breakpoints.down('md')]: {
      padding: '32px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 24px'
    }
  },
  textCopyright: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      marginRight: 8,
    }
  }
}))

export default useStyles