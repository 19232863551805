import { useContext, useEffect } from 'react'

// AOS JS
import AOS from 'aos'

// ASSETS
import LogoWorxWhite from 'assets/images/logos/company-worx-white-full.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconFacebook from '@mui/icons-material/Facebook'
import IconLinkedIn from '@mui/icons-material/LinkedIn'
import IconInstagram from '@mui/icons-material/Instagram'

// STYLES 
import useStyles from './footerUseStyles'

const Footer = () => {
  const classes = useStyles()

  const { breakpointType } = useContext(AllPagesContext)

  const listMenu =[
    {
      'title' : 'Product',
      'children': [
        {
          'title' : 'Features',
        },
        {
          'title' : 'Pricing',
        },
      ]
    },
    {
      'title' : 'Solutions',
      'children': [
        {
          'title' : 'Retail',
        },
        {
          'title' : 'Law Enforcement',
        },
        {
          'title' : 'Government',
        },
        {
          'title' : 'Outsourcing',
        }
      ]
    },
    {
      'title' : 'Other',
      'children': [
        {
          'title' : 'Privacy Policy',
        },
        {
          'title' : 'Terms of Service',
        },
        {
          'title' : 'Support Regulation',
        }
      ]
    },
  ]

  const socialMediaList = [
    {
      icon: IconFacebook,
      url: process.env.REACT_APP_SOCIAL_MEDIA_FACEBOOK_URL,
    },
    {
      icon: IconLinkedIn,
      url: process.env.REACT_APP_SOCIAL_MEDIA_LINKED_IN_URL,
    },
    {
      icon: IconInstagram,
      url: process.env.REACT_APP_SOCIAL_MEDIA_INSTAGRAM_URL,
    },
  ]

  const handleSocialMediaIconClick = (inputEvent, inputUrl) => {
    inputEvent.preventDefault()
    
    const newWindow = window.open(inputUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const loadFooter = async (inputIsMounted) => {
    if(inputIsMounted) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadFooter(isMounted, abortController)
    AOS.init()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack 
      className={`${classes.mainContainer} no-zoom`}
      flexDirection='row'
      justifyContent='center'
    >
      <Stack 
        justifyContent='space-between' 
        className='containerMaxWidth1400 zoom' 
        flex='1'
      >
        {/* TOP CONTENT */}
        <Stack 
          direction={breakpointType !== 'xs' ? 'row' : 'column'} 
          alignItems='flex-start' 
          justifyContent='space-between' 
        >
          {/* COMPANY LOGO */}
          <Stack
            data-aos='fade-down'
            data-aos-duration='2000'>
            <Link href='/'>
              <Box component='img'
                src={LogoWorxWhite}
                alt='Worx Logo'
                className={classes.logoCompany}
              />
            </Link>
            <Typography className={classes.textInfo}>
              PT. Virtue Digital Indonesia
            </Typography>
            <Typography className={classes.textInfo}>
              Jakarta, Indonesia
            </Typography>
          </Stack>

          {/* MENU */}
          <Box className={classes.gridContainer}>
            <Grid 
              className={classes.gridFlex} 
              container
            >
              {listMenu.map((item,index) =>(
                <Grid 
                  key={index} 
                  item xs={6} 
                  sm={4} 
                  lg={3}
                >
                  <Stack
                    data-aos='fade-down'
                    data-aos-duration='2000'
                  >
                    <Typography 
                      className={classes.textTitle} 
                      align='left'
                    > 
                      {item.title} 
                    </Typography>
                    <Stack className={classes.links}>
                      {item.children.map((childs) => (
                        <Link 
                          key={childs.title} 
                          href={`/${item.title === 'Solutions' ? 'solution-' : ''}${((childs.title).replaceAll(' ', '-')).toLowerCase()}`} 
                          className={classes.link} 
                          underline='none'
                        >
                          <Typography 
                            className={classes.textLink} 
                            align='left'
                          >
                            {childs.title} 
                          </Typography>
                        </Link>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>

        {/* BOTTOM CONTENT */}
        <Stack 
          direction={breakpointType !== 'xs' ? 'row' : 'column'} 
          justifyContent='space-between'
          alignItems='center'
          spacing='16px'
        >
          {/* TYPOGRHAPY */}
          <Typography className={classes.textCopyright}>
            &copy; Worx.id. 2022. All rights reserved
          </Typography>

          {/* SOCIAL MEDIAS */}
          <Stack 
            direction='row'
            spacing='24px'
          >
            {socialMediaList.map((item, index) => (
              <Link 
                key={index}  
                href={item.url}
                onClick={(event) => handleSocialMediaIconClick(event, item.url)}
                className='flex alignItemsCenter'
              >
                <item.icon className={classes.icon}/>
              </Link>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Footer