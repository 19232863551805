// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    background: theme.palette.common.white,
    boxShadow: 'none',
    color: theme.palette.text.primary,
    padding: '25px 140px',
    fontFamily: theme.typography.fontFamily,
    borderBottom: `3px solid ${theme.palette.text.primary}`,
    marginBottom: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  toolbar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: 0,
    minHeight: '38px',
    width: '100%',
    margin: '0 auto',
  },
  logoWrap: {
    flex: '0 1 auto',
    height: 32.19
  },
  listMenuWrap: {
    flex: '1 1 auto',
    padding: 0,
    justifyContent: 'center'
  },
  buttonWrap: {
    flex: '0 1 auto'
  },
  listButton: {
    display: 'flex',
    padding: 0
  },
  listButtonItem: {
    flex: '0 1 auto',
    padding: '0 20px 0 0',
    '&:last-child': {
      padding: 0
    }
  },
  buttonOutlinedCustom: {
    whiteSpace: 'nowrap',
    height: 44,
    minWidth: 132,
    fontSize: 16
  },
  listMenu: {
    padding: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  solutionMenuItem: {
    display: 'flex',
    flex: '0 1 auto',
    width: 'auto',
    zIndex: theme.zIndex.tooltip,
  },
  tooltipMenu: {
    marginTop: 8,
    border: `2px solid ${theme.palette.common.black}`,
  },
  listMenuItem: {
    flex: '0 1 auto',
    width: 'auto',
    padding: '4px 60px 4px 0',
    '&:last-child': {
      padding: '4px 0 4px 0',
    }
  },
  listMenuLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid transparent',
    padding: '5px 0',
    lineHeight: '14px',
    color: theme.palette.text.primary,
    '&.active': {
      color: theme.palette.primary.main,
      textDecorationColor: theme.palette.primary.main,
      textUnderlineOffset: '12px',
      textDecorationThickness: '2px'
    },
    '&:hover': {
      color: theme.palette.primary.main,
      textDecorationColor: theme.palette.primary.main,
      textUnderlineOffset: '12px',
      textDecorationThickness: '2px'
    },
  },
  menuSolutions: {
    marginRight: 40,
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    padding: '8px auto',
    '& .menuPopupItem.active': {
      color: theme.palette.primary.main,
    }
  }
}))

export default useStyles