import { useContext } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import Footer from 'components/Footer/Footer'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './landingUseStyles'

const Main = (props) => {
  const { 
    children,
  } = props

  // CONTEXTS
  const { breakpointType } = useContext(AllPagesContext)

  const classes = useStyles()

  return (
    <Stack className={classes.root}>
      {/* APPBAR */}
      <AppBar/>

      {/* CHILDREN */}
      <Stack>
        {children}
      </Stack>

      {/* FOOTER */}
      <Footer/>
    </Stack>
  )
}

Main.defaultProps = {
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main