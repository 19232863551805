const LogoWorxBlack = (props) => {
  return (
    <svg {...props} viewBox='0 0 118 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path className='appLogoRed' fillRule='evenodd' clipRule='evenodd' d='M15.8135 9.01289L23.461 1.35546C17.1263 -0.905551 8.59177 -0.999601 0 6.3034V15.1173C0.419708 20.5735 4.15511 32.1574 15.7391 34.8436C20.2859 34.4239 29.8413 29.891 31.688 15.1173V10.1393L15.8138 26.2401L4.61621 15.1178L8.00258 11.7269L15.8138 19.0344L29.9758 4.8539C29.4369 4.44666 28.8468 4.03994 28.2102 3.64494L15.8135 16.2186V9.01289Z'/>
      <path d='M43.9031 27.2556C43.6213 27.2556 43.3958 27.1804 43.2267 27.0301C43.0763 26.861 42.973 26.6543 42.9166 26.41L39.5624 8.34221C39.5624 8.28584 39.553 8.23886 39.5342 8.20127C39.5342 8.16369 39.5342 8.1355 39.5342 8.11671C39.5342 7.94759 39.5906 7.80666 39.7033 7.69391C39.8348 7.58116 39.9758 7.52479 40.1261 7.52479H42.6066C43.0763 7.52479 43.3394 7.7127 43.3958 8.08853L45.7353 20.8572L48.2721 12.5984C48.3285 12.4481 48.4224 12.2978 48.554 12.1474C48.7043 11.9783 48.9204 11.8938 49.2023 11.8938H50.7244C51.025 11.8938 51.2411 11.9783 51.3727 12.1474C51.5042 12.2978 51.5888 12.4481 51.6264 12.5984L54.1914 20.829L56.5309 8.08853C56.5872 7.7127 56.8503 7.52479 57.3201 7.52479H59.8006C59.9509 7.52479 60.0824 7.58116 60.1952 7.69391C60.3079 7.80666 60.3643 7.94759 60.3643 8.11671C60.3643 8.1355 60.3643 8.16369 60.3643 8.20127C60.3643 8.23886 60.3549 8.28584 60.3361 8.34221L56.9819 26.41C56.9443 26.6543 56.8409 26.861 56.6718 27.0301C56.5027 27.1804 56.2772 27.2556 55.9953 27.2556H54.1068C53.8249 27.2556 53.5994 27.1804 53.4303 27.0301C53.28 26.8798 53.1766 26.7201 53.1203 26.5509L49.9633 17.1365L46.8064 26.5509C46.7312 26.7201 46.6185 26.8798 46.4682 27.0301C46.3178 27.1804 46.0923 27.2556 45.7917 27.2556H43.9031Z'/>
      <path d='M71.0357 27.5375C69.3632 27.5375 67.9257 27.265 66.7231 26.7201C65.5204 26.1563 64.5809 25.3013 63.9044 24.1551C63.2279 22.99 62.8615 21.5431 62.8051 19.8143C62.7863 19.0062 62.7769 18.2076 62.7769 17.4184C62.7769 16.6292 62.7863 15.8211 62.8051 14.9943C62.8615 13.2843 63.2373 11.8562 63.9326 10.7099C64.6279 9.56364 65.5768 8.69924 66.7795 8.11671C68.0009 7.53418 69.4196 7.24292 71.0357 7.24292C72.6517 7.24292 74.0705 7.53418 75.2919 8.11671C76.5133 8.69924 77.4717 9.56364 78.167 10.7099C78.8622 11.8562 79.2381 13.2843 79.2944 14.9943C79.332 15.8211 79.3508 16.6292 79.3508 17.4184C79.3508 18.2076 79.332 19.0062 79.2944 19.8143C79.2381 21.5431 78.8716 22.99 78.1951 24.1551C77.5187 25.3013 76.5697 26.1563 75.3483 26.7201C74.1456 27.265 72.7081 27.5375 71.0357 27.5375ZM71.0357 24.296C72.2571 24.296 73.253 23.939 74.0235 23.2249C74.7939 22.492 75.2073 21.3082 75.2637 19.6733C75.3013 18.8465 75.3201 18.0855 75.3201 17.3902C75.3201 16.6761 75.3013 15.9151 75.2637 15.1071C75.2261 14.0172 75.0194 13.134 74.6436 12.4575C74.2866 11.7622 73.798 11.2642 73.1779 10.9636C72.5578 10.6441 71.8437 10.4844 71.0357 10.4844C70.2464 10.4844 69.5418 10.6441 68.9217 10.9636C68.3015 11.2642 67.8036 11.7622 67.4277 12.4575C67.0707 13.134 66.8734 14.0172 66.8358 15.1071C66.817 15.9151 66.8076 16.6761 66.8076 17.3902C66.8076 18.0855 66.817 18.8465 66.8358 19.6733C66.8922 21.3082 67.3056 22.492 68.076 23.2249C68.8465 23.939 69.833 24.296 71.0357 24.296Z'/>
      <path d='M83.7286 27.2556C83.5407 27.2556 83.3809 27.1898 83.2494 27.0583C83.1179 26.9268 83.0521 26.767 83.0521 26.5791V8.22946C83.0521 8.02276 83.1179 7.85364 83.2494 7.7221C83.3809 7.59056 83.5407 7.52479 83.7286 7.52479H91.029C93.3027 7.52479 95.0973 8.05094 96.4127 9.10325C97.7281 10.1556 98.3858 11.6871 98.3858 13.6977C98.3858 15.0883 98.0381 16.2439 97.3428 17.1647C96.6664 18.0855 95.755 18.7526 94.6087 19.166L98.724 26.3536C98.7804 26.4664 98.8086 26.5697 98.8086 26.6637C98.8086 26.8328 98.7428 26.9737 98.6113 27.0865C98.4985 27.1992 98.367 27.2556 98.2166 27.2556H95.6516C95.3322 27.2556 95.0879 27.171 94.9188 27.0019C94.7497 26.8328 94.6181 26.6637 94.5242 26.4946L90.888 19.8143H86.9701V26.5791C86.9701 26.767 86.9043 26.9268 86.7728 27.0583C86.66 27.1898 86.5003 27.2556 86.2936 27.2556H83.7286ZM86.9701 16.6292H90.9444C92.0907 16.6292 92.9457 16.3755 93.5094 15.8681C94.0732 15.342 94.355 14.6091 94.355 13.6695C94.355 12.73 94.0732 11.9971 93.5094 11.471C92.9645 10.926 92.1095 10.6535 90.9444 10.6535H86.9701V16.6292Z'/>
      <path d='M100.918 27.2556C100.749 27.2556 100.608 27.1992 100.496 27.0865C100.383 26.9737 100.326 26.8328 100.326 26.6637C100.326 26.6073 100.336 26.5509 100.355 26.4946C100.373 26.4382 100.392 26.3818 100.411 26.3254L106.725 17.1929L100.806 8.45496C100.749 8.34221 100.721 8.22946 100.721 8.11671C100.721 7.94759 100.777 7.80666 100.89 7.69391C101.003 7.58116 101.134 7.52479 101.285 7.52479H104.188C104.414 7.52479 104.592 7.58116 104.724 7.69391C104.855 7.80666 104.968 7.9288 105.062 8.06034L109.205 14.1487L113.377 8.06034C113.452 7.9288 113.556 7.80666 113.687 7.69391C113.837 7.58116 114.025 7.52479 114.251 7.52479H116.985C117.154 7.52479 117.295 7.58116 117.408 7.69391C117.52 7.80666 117.577 7.94759 117.577 8.11671C117.577 8.22946 117.539 8.34221 117.464 8.45496L111.573 17.1929L117.887 26.3254C117.924 26.3818 117.953 26.4382 117.971 26.4946C117.99 26.5509 118 26.6073 118 26.6637C118 26.8328 117.934 26.9737 117.802 27.0865C117.69 27.1992 117.558 27.2556 117.408 27.2556H114.42C114.194 27.2556 114.016 27.1992 113.884 27.0865C113.753 26.9737 113.649 26.861 113.574 26.7482L109.064 20.378L104.639 26.7482C104.564 26.861 104.461 26.9737 104.329 27.0865C104.197 27.1992 104.01 27.2556 103.765 27.2556H100.918Z'/>
    </svg>
  )
}

export default LogoWorxBlack