// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    minHeight: 420,
    padding: '60px 120px 24px',
    background: theme.palette.common.black,
    [theme.breakpoints.only('md')]: {
      padding: 60,
    },
    [theme.breakpoints.only('sm')]: {
      padding: 40,
    },
    [theme.breakpoints.only('xs')]: {
      padding: 24,
    },
  },
  topContentContainer: {
    marginBottom: 93,
  },
  logoCompany: {
    width: 160,
    cursor: 'pointer',
    marginBottom: 24
  },
  textTitle: {
    color: theme.palette.common.white,
    fontWeight: 700,
    marginTop: 16
  },
  textLink: {
    fontWeight: 400,
  },
  links: {
    marginTop: 20,
  },
  link: {
    marginBottom: 16,
    color: theme.palette.text.contrastTextSecondary,
    '&:hover': {
      color: theme.palette.common.white
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: 8,
    },
  },
  gridContainer: {
    width: '80%',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginTop: 24,
      marginBottom: 24,
    },
  },
  gridFlex: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textInfo: {
    color: theme.palette.text.contrastTextSecondary,
    fontWeight: 400,
    marginTop: 12
  },
  icon: {
    color: theme.palette.common.white,
    cursor: 'pointer',
    width: 20,
    height: 20,
  },
  textCopyright: {
    color: theme.palette.text.contrastTextSecondary,
    fontWeight: 400,
  },
}))

export default useStyles
