// APIS
import axiosWorx from 'apis/axiosWorx'

export const getRootData = async () => {
  try {
    const response = await axiosWorx.get('/root')
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}