import { useContext, useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// ASSETS
import LogoWorxBlack from '../LogoWorxBlack'

// COMPONENTS
import ButtonGithub from 'components/ButtonGithub'

// CONSTANTS
import { buttonList, navigationList, solutionList } from '../appBarConstants'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// GSAP
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// MUIS
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import MuiAppBar from '@mui/material/AppBar'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconClose from '@mui/icons-material/Close'
import IconCopyright from '@mui/icons-material/Copyright'
import IconMenu from '@mui/icons-material/Menu'

// STYLES
import useStyles from './appBarMobileUseStyles'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <Box {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const AppBarMobile = (props) => {
  // PROPS & OTHERS
  const { isNavigationItemActive, isPageNoHeroSection } = props
  const location = useLocation()

  // INIT PLUGIN GSAP
  gsap.registerPlugin(ScrollTrigger)
  const scrollPosition = useScrollTrigger({
    disableHysteresis: true,
  })

  // CONTEXTS
  const { breakpointType } = useContext(AllPagesContext)

  // REFS
  const animateMenuRef = useRef(gsap.timeline())
  const animateHamburgerRef = useRef(gsap.timeline({ reversed:true }))

  // STYLES
  const classes = useStyles()

  // STATE
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [selectedParent, setSelectedParent] = useState('')

  const getPaddingAppBarClose = () => {
    if (breakpointType === 'xs') return '16px 24px'
    else if(breakpointType === 'sm') return '16px 40px'
    else if(breakpointType === 'md') return '16px 60px'
  }

  const getPaddingAppBarOpen = () => {
    if (breakpointType === 'xs') return '16px 24px'
    else if(breakpointType === 'sm') return '16px 40px'
    else if(breakpointType === 'md') return '16px 60px'
  }

  // HANDLE PARENT CLICK
  const handleParentClick = (event, title) => {
    if (title !== selectedParent) setSelectedParent(title)
    else setSelectedParent('')
  }

  // ANIMATE HAMBURGER
  const animateHamburgerMenu = () => {
    return animateHamburgerRef.current.to('.upper', 0.5, {attr: {d: 'M8,2 L2,8'}, x: 1,}, 'start')
      .to('.middle', 0.5, {autoAlpha: 0}, 'start')
      .to('.lower', 0.5, {attr: {d: 'M8,8 L2,2'}, x: 1 }, 'start')
  }

  // ANIMATE MENU TOGGLE
  const animateMenuToggle = () => {
    if(isMenuOpened) { // ANIMATE CLOSE
      // TOOLBAR
      animateMenuRef.current.to('#menu-toolbar', {
        duration: 0.1,
        padding: getPaddingAppBarClose(),
        ease: 'Power1.easeIn',
      })

      animateMenuRef.current.to('#menu-toggle', {
        duration: 0.4,
        opacity: 0,
        ease: 'Power1.easeIn',
      })
      animateMenuRef.current.to('#menu-toggle', {
        duration: 0.1,
        display: 'none',
        ease: 'Power1.easeIn',
      })
      
    } else { // ANIMATE OPEN
      animateMenuRef.current.to('#menu-toggle', {
        duration: 0,
        display: 'flex',
        ease: 'Power1.easeIn',
      })
      // TOOLBAR
      animateMenuRef.current.to('#menu-toolbar', {
        duration: 0.1,
        padding: getPaddingAppBarOpen(),
        ease: 'Power1.easeIn',
      })
      animateMenuRef.current.to('#menu-toggle', {
        duration: 0.4,
        opacity: 1,
        ease: 'Power1.easeIn',
      })
    }

    animateHamburgerRef.current.reversed() ? animateHamburgerRef.current.play() : animateHamburgerRef.current.reverse()
  }

  // ANIMATE SCROLL APPBAR
  const animateAppBarScroll = () => {
    ScrollTrigger.create({
      start: 'top -40',
      end: 99999,
      toggleClass: {className: 'appbarResponsive--scrolled', targets: '#menu-root'}
    })
  }

  useEffect(() => {
    animateHamburgerMenu()
    animateAppBarScroll()
  }, [])

  return (
    <>
      <MuiAppBar
        id='menu-root'
        className={`${!isPageNoHeroSection() && 'appbarResponsive'} ${(scrollPosition) && 'appbarResponsive--scrolled'}`}
        position='fixed'
      >
        <Stack
          id='menu-toolbar'
          className={`${classes.toolbar} menuToolbar ${isMenuOpened && classes.toolbarActive} ${isPageNoHeroSection() && classes.toolbarActive}`}
        >
          <Stack direction='row' alignItems='center' className={classes.menuActionWrap}>
            {/* LOGO */}
            <Box className={classes.logoWrap}>
              <Link href='/' className={classes.logolinkWrap}>
                <LogoWorxBlack width='72' height='40' className='appbarLogo'/>
              </Link>
            </Box>
           
            {/* GITHUB INFO */}
            <ButtonGithub />

            <IconButton
              onClick={() => {
                setIsMenuOpened(!isMenuOpened)
                animateMenuToggle()
              }}
              className={classes.buttonHamburger}
            >
              {
                isMenuOpened ? (
                  <IconClose className={classes.iconClose} />
                ) : (
                  <IconMenu className={`${classes.iconHamburger} barHamburger`} />
                )
              }
            </IconButton>
          </Stack>
        </Stack>
      </MuiAppBar>

      
      <Stack
        id='menu-toggle'
        className={classes.menuContent}
        sx={{
          borderTop: isMenuOpened && '1px solid #000000',
        }}
      >
        {/* LIST MENU */}
        <Stack direction='row' className={classes.listMenuWrap}>
          <List className={classes.listMenuParent}>
            {navigationList && navigationList.map((itemParent, index) => (
              <ListItem key={index} className={classes.menuItemParent}>
                <Stack direction='column' width='100%'>
                  <Link
                    className={
                      isNavigationItemActive(location.pathname, itemParent.title.toLowerCase())
                        ? `${classes.menuItemLinkParent} active`
                        : classes.menuItemLinkParent
                    }
                    underline='none'
                    href={itemParent.title !== 'Solutions' ? `/${itemParent.title.toLowerCase()}` : '#'}
                    onClick={(event) => handleParentClick(event, itemParent.title)}
                  >
                    <Typography variant='h6' fontWeight={500}>
                      {itemParent.title}
                    </Typography>

                    {itemParent.title === 'Solutions' && (
                      <ExpandMore expand={selectedParent === itemParent.title}>
                        <IconArrowDropDown className={isNavigationItemActive(location.pathname, itemParent.title.toLowerCase())
                          ? `${classes.iconMenuDropdown} active`
                          : classes.iconMenuDropdown}
                        />
                      </ExpandMore>
                    )}
                  </Link>

                  {/* CHILDREN */}
                  {itemParent.title === 'Solutions' &&  (
                    <Collapse in={selectedParent === itemParent.title} timeout='auto' unmountOnExit>
                      <List className={classes.listMenuChildren}>
                        {solutionList?.map((itemChildren, indexChildren) => (
                          <MenuItem disableRipple key={indexChildren} className={classes.menuItemChildren}>
                            <Link
                              underline='none'
                              href={itemChildren.path}
                              className={
                                isNavigationItemActive(location.pathname.replace('solution-', ''), itemChildren.title.toLowerCase().replace(/ /g, '-'))
                                  ? `${classes.menuItemLinkChildren} active`
                                  : classes.menuItemLinkChildren
                              }
                            >
                              <Typography variant='h6' fontWeight={500}>{itemChildren.title}</Typography>
                            </Link>
                          </MenuItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>

        <Divider className={classes.menuContentDivider} />

        {/* LIST BUTTON */}
        <Stack justifyContent='flex-end' flex={1} direction='column-reverse' className={classes.listButtonWrap}>
          {buttonList.map((item, index) => (
            <Button
              key={index}
              variant={item.variant}
              color={item.color}
              href={item.path}
              className={`${classes.buttonMenu} ${item?.classes}`}
            >
              <Typography variant='h6'>{item.name}</Typography>
            </Button>
          ))}
        </Stack>

        {/* FOOTER */}
        <Stack className={classes.footerWrap}>
          <Typography
            color='text.secondary'
            variant='caption'
            className={classes.textCopyright}
          >
            <IconCopyright />
            Worx.id. 2022. All rights reserved
          </Typography>
        </Stack>
      </Stack>     
    </>
  )
}

export default AppBarMobile