// CONSTANTS
import { values } from 'constants/values'

export const navigationList = [
  { title: 'Features' },
  { title: 'Solutions' },
  { title: 'Pricing' },
  { title: 'Blogs' },
]
export const buttonList = [
  {
    name: 'Log In',
    path: `${process.env.REACT_APP_DEPLOYED_DASHBOARD_APP_BASE_URL}/sign-in`,
    variant: 'outlined',
    classes: 'buttonWhite',
  },
  {
    name: 'Get Started',
    path: values.hrefGetStarted,
    variant: 'contained',
    color: 'secondary',
  }
]
export const solutionList = [
  {
    title: 'Retail',
    path: '/solution-retail'
  },
  {
    title: 'Law Enforcement',
    path: '/solution-law-enforcement'
  },
  {
    title: 'Government',
    path: '/solution-government'
  },
  {
    title: 'Outsourcing',
    path: '/solution-outsourcing'
  }
]