// APIS
import axiosGithub from 'apis/axiosGithub'

// GET LANDING HOME API
const getGithubData = async () => {
  try {
    const response = await axiosGithub.get()
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export {getGithubData}